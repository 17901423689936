<template>
  <div>
    <eden-page-header :title="'Services'" />
    <eden-loader v-if="loading" />
    <el-row type="flex" :gutter="20" class="is-flex-wrap">
      <el-col v-for="(service, i) in services" :key="i" :md="8">
        <div :class="['eden-service', service.slug]">
          <router-link
            :to="{
              name: 'services.service',
              params: { service: service.slug },
            }"
          />
          <div class="eden-service--title">
            <p>{{ service.service_name }}</p>
            <img
              :src="getServiceImage(service.slug)"
              :alt="service.service_name"
            />
          </div>
          <div class="eden-service--types">
            <p>Categories</p>
            <span>{{ service.service_types_count }} types</span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as actions from "@/store/action-types";

export default {
  name: "Services",
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    services() {
      return this.$store.getters.services;
    },
  },
  created() {
    if (!this.services.length) {
      this.getServices();
    }
  },
  methods: {
    getServices() {
      this.fetching = true;
      this.$store.dispatch(actions.GET_SERVICES).then(() => {
        this.fetching = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.eden-service {
  height: 170px;
  width: 85%;
  margin: 0 auto 40px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  position: relative;
  cursor: pointer;

  a {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &.beauty {
    background: var(--eden-pink-septenary);
  }

  &.cleaning {
    background: var(--eden-orange-septenary);
  }

  &.laundry {
    background: var(--eden-purple-septenary);
  }

  &.meal {
    background: var(--eden-green-septenary);
  }

  &--title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      color: var(--eden-grey-primary);
      font-weight: 500;
    }

    img {
      height: 36px;
      width: auto;
    }
  }

  &--types {
    p {
      font-size: 0.825rem;
    }

    span {
      font-size: 0.825rem;
      color: var(--eden-grey-tertiary);
    }
  }
}
</style>
